<template >
  <div class="content">
    <PageHeader title="Webchat" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-8">
          <div class="card">
            <div class="card-body block-el p-0">
              <div>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-scr
                    class="btn-accordion default-color"
                  >
                    Script
                    <span class="when-open">
                      <i class="fas fa-angle-down"></i>
                    </span>
                    <span class="when-closed">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-scr"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="render-code">
                      <span>{{$t('webchat-component.copy-code')}}</span>
                      <textarea class="form-control" disabled v-model="renderScript"></textarea>
                      <button class="btn btn-info btn-sm to-right btn-copy" @click="copyData">{{$t('generic-str.copy')}}</button>
                      <div style="clear:both;"></div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </div>
              <div>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-1
                    class="btn-accordion default-color"
                  >
                    {{$tc('generic-str.color', 2)}}
                    <span class="when-open">
                      <i class="fas fa-angle-down"></i>
                    </span>
                    <span class="when-closed">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="">
                      <div class="">
                        <ul class="box-widget">
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#383D45'}" style="background-color: #383D45;" @click="changeColor('#383D45')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#F44336'}" style="background-color: #F44336;" @click="changeColor('#F44336')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#E91E63'}" style="background-color: #E91E63;" @click="changeColor('#E91E63')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#9C27B0'}" style="background-color: #9C27B0;" @click="changeColor('#9C27B0')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#673AB7'}" style="background-color: #673AB7;" @click="changeColor('#673AB7')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#3F51B5'}" style="background-color: #3F51B5;" @click="changeColor('#3F51B5')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#2196F3'}" style="background-color: #2196F3;" @click="changeColor('#2196F3')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#03A9F4'}" style="background-color: #03A9F4;" @click="changeColor('#03A9F4')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#00BCD4'}" style="background-color: #00BCD4;" @click="changeColor('#00BCD4')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#009688'}" style="background-color: #009688;" @click="changeColor('#009688')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#4CAF50'}" style="background-color: #4CAF50;" @click="changeColor('#4CAF50')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#8BC34A'}" style="background-color: #8BC34A;" @click="changeColor('#8BC34A')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#CDDC39'}" style="background-color: #CDDC39;" @click="changeColor('#CDDC39')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#FFEB3B'}" style="background-color: #FFEB3B;" @click="changeColor('#FFEB3B')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#FFC107'}" style="background-color: #FFC107;" @click="changeColor('#FFC107')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#FF5722'}" style="background-color: #FF5722;" @click="changeColor('#FF5722')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#795548'}" style="background-color: #795548;" @click="changeColor('#795548')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#9E9E9E'}" style="background-color: #9E9E9E;" @click="changeColor('#9E9E9E')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#607D8B'}" style="background-color: #607D8B;" @click="changeColor('#607D8B')"></li>
                          <li class="box-widget-item" :class="{ 'active_color': form.color ==  '#0E1421'}" style="background-color: #0E1421;" @click="changeColor('#0E1421')"></li>
                        </ul>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </div>
              <div>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-2
                    class="btn-accordion default-color"
                  >
                    {{$tc('options-div.types.text', 2)}}
                    <span class="when-open">
                      <i class="fas fa-angle-down"></i>
                    </span>
                    <span class="when-closed">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion-2"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <label class="control-label text-left">{{$t('webchat-component.title-online')}}</label>
                          <input
                            type="text"
                            class="form-control"
                            required
                            v-model="form.online_title"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-12">
                          <label class="control-label text-left">{{$t('webchat-component.title-offline')}}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="form.offline_title"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-12">
                          <label class="control-label text-left">{{$t('webchat-component.title-form-offline')}}</label>
                          <textarea
                            type="text"
                            class="form-control"
                            v-model="form.offline_text"></textarea>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-12">
                          <label class="control-label text-left">{{$t('webchat-component.welcome-text')}}</label>
                          <textarea
                            type="text"
                            class="form-control"
                            v-model="form.welcome_text"></textarea>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </div>
              <div>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-form
                    class="btn-accordion default-color"
                  >
                    {{$t('generic-str.form')}}
                    <span class="when-open">
                      <i class="fas fa-angle-down"></i>
                    </span>
                    <span class="when-closed">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-form"
                  accordion="my-accordion-form"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <p>{{$t('webchat-component.form')}}</p>
                          <table class="table table-inputs">
                            <thead>
                              <tr>
                                <th>{{$t('sms.infos.filters.field.label')}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <toggle-button v-model="form.has_email" class="mt5" :sync="true" :value="form.has_email" @change="changeToggle('email')"/>
                                  <strong> Email</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <toggle-button v-model="form.has_phone" class="mt5" :sync="true" :value="form.has_phone" @change="changeToggle('phone')"/>
                                  <strong> {{$tc('generic-str.tel', 1)}}</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </div>
              <div>
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-3
                    class="btn-accordion default-color"
                  >
                    {{$t('generic-str.position')}}
                    <span class="when-open">
                      <i class="fas fa-angle-down"></i>
                    </span>
                    <span class="when-closed">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion-3"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <div class="place__2bTkV">
                            <div class="boxContainer__3LS_q">
                                <div class="text__1FtTq">{{$t('webchat-component.chat-position')}}</div>
                                <div class="box__chat-pos bottom__3oEP_ padding-0__38h9Z" :class="{ 'active_box_chat': form.position ===  'b-left'}" @click="changePos('b-left')"></div>
                                <div class="box__chat-pos bottom__3oEP_ padding-50__30Irj" :class="{ 'active_box_chat': form.position ===  'b-center'}" @click="changePos('b-center')"></div>
                                <div class="box__chat-pos bottom__3oEP_ padding-100__2YkFV" :class="{ 'active_box_chat': form.position ===  'b-right'}" @click="changePos('b-right')"></div>
                                <div class="box__chat-pos left__33EA_ padding-0__38h9Z"></div>
                                <div class="box__chat-pos left__33EA_ padding-50__30Irj pos-inactive" :class="{ 'active_box_chat': form.position ==  's-left-t'}"></div>
                                <div class="box__chat-pos left__33EA_ padding-100__2YkFV pos-inactive" :class="{ 'active_box_chat': form.position ==  's-left-b'}"></div>
                                <div class="box__chat-pos right__1X0Q3 padding-0__38h9Z"></div>
                                <div class="box__chat-pos right__1X0Q3 padding-50__30Irj pos-inactive" :class="{ 'active_box_chat': form.position ==  's-right-t'}"></div>
                                <div class="box__chat-pos right__1X0Q3 padding-100__2YkFV pos-inactive" :class="{ 'active_box_chat': form.position ==  's-right-b'}"></div>
                                <div class="box__chat-pos top__2W2WB padding-0__38h9Z"></div>
                                <div class="box__chat-pos top__2W2WB padding-50__30Irj"></div>
                                <div class="box__chat-pos top__2W2WB padding-100__2YkFV"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </div>
              <template >
                <div >
                  <beautiful-chat
                    :participants="participants"
                    :titleImageUrl="titleImageUrl"
                    :onMessageWasSent="onMessageWasSent"
                    :messageList="messageList"
                    :newMessagesCount="newMessagesCount"
                    :isOpen="isChatOpen"
                    :close="closeChat"
                    :open="openChat"
                    :showEmoji="true"
                    :showFile="false"
                    :showEdition="true"
                    :showDeletion="true"
                    :showTypingIndicator="showTypingIndicator"
                    :showLauncher="true"
                    :showCloseButton="true"
                    :colors="colors"
                    :placeholder="placeholder"
                    :alwaysScrollToBottom="alwaysScrollToBottom"
                    :disableUserListToggle="false"
                    :messageStyling="messageStyling"
                    @onType="handleOnType"
                    @edit="editMessage">
                    <template v-slot:header>
                      <!-- {{form.online_title}} -->
                      <span :style="'fontWeight: bold; fontSize: 18px; display: flex; alignItems: center; marginLeft: 5px'">{{$t('webchat-component.placeholder.online')}}</span>
                    </template>
                  </beautiful-chat>
                </div>
              </template>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-12">
                  <button class="btn btn-primary"
                    @click="updateWidget"
                    :class="{ 'qt-loader qt-loader-mini qt-loader-left': isSending }"
                    :disabled="isSending">{{$t('generic-str.save')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import axios from '@/plugins/axios';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  name: 'Webchat',
  components: {
    PageHeader,
    ToggleButton,
  },
  data() {
    return {
      renderScript: this.$t('webchat-component.script-creating'),
      isSending: false,
      chatId: null,
      default: {
        color: '#9C27B0',
        online_title: this.$t('webchat-component.placeholder.online'),
        offline_title: this.$t('webchat-component.placeholder.offline'),
        offline_text: this.$t('webchat-component.placeholder.form-offline'),
        welcome_text: this.$t('webchat-component.placeholder.welcome-text'),
        position: 'b-right',
        has_phone: false,
        has_email: false,
        email_required: false,
        phone_required: false,
      },
      form: {},
      icons: {
        open: {},
        close: {},
      },
      participants: [
        {
          id: 'user1',
          name: 'Marcos',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4',
        },
        {
          id: 'user2',
          name: 'Suporte',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4',
        },
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      messageList: [
        { type: 'text', author: 'me', data: { text: this.$t('webchat-component.chat-example.hi') } },
        { type: 'text', author: 'user1', data: { text: this.$t('webchat-component.chat-example.test') } },
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      placeholder: this.$t('webchat-component.chat-example.leave-message'),
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#9C27B0',
          text: '#ffffff',
        },
        launcher: {
          bg: '#9C27B0',
        },
        messageList: {
          bg: '#ffffff',
        },
        sentMessage: {
          bg: '#9C27B0',
          text: '#ffffff',
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222',
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867',
        },
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    };
  },
  computed: {
  },
  created() {
    this.isChatOpen = true;
  },
  mounted() {
    this.getWidget();
  },
  methods: {
    async getWidget() {
      let response;

      try {
        response = await axios().get('webchat-server');
      } catch (ee) {
        response = await axios().post('webchat-server', {
          account_id: this.$store.state.account.id,
        });
      }

      console.log('1[widget.vue] => ', response.data);
      console.log('2[widget.vue] => ', response.data.id);

      this.chatId = response.data.id;

      // Default
      this.renderScript = `<script src="${process.env.VUE_APP_WIDGET}${this.chatId}"><\x2Fscript>`;

      const dataWidget = await axios().get(`/widget/${this.chatId}`, {
        account_id: this.$store.state.account.id,
      });
      this.form = dataWidget.data;
      this.initData();
    },
    changeToggle(field) {
      this.$forceUpdate();
      if (field === 'email' && !this.form.has_email) {
        this.form.has_phone = true;
      }
      if (field === 'phone' && !this.form.has_phone) {
        this.form.has_email = true;
      }
    },
    initData() {
      if (this.form.color === undefined) {
        this.colors.header.bg = this.default.color;
        this.colors.launcher.bg = this.default.color;
        this.colors.header.bg = this.default.color;
        this.colors.sentMessage.bg = this.default.color;
        this.form.color = this.default.color;
      } else {
        this.colors.header.bg = this.form.color;
        this.colors.launcher.bg = this.form.color;
        this.colors.header.bg = this.form.color;
        this.colors.sentMessage.bg = this.form.color;
      }
      if (this.form.online_title === undefined) {
        this.form.online_title = this.default.online_title;
      }
      if (this.form.offline_title === undefined) {
        this.form.offline_title = this.default.offline_title;
      }
      if (this.form.welcome_text === undefined) {
        this.form.welcome_text = this.default.welcome_text;
      }
      if (this.form.offline_text === undefined) {
        this.form.offline_text = this.default.offline_text;
      }
      if (this.form.position === undefined) {
        this.form.position = this.default.position;
      }
      if (this.form.has_phone === undefined) {
        this.form.has_phone = this.default.has_phone;
      }
      if (this.form.has_email === undefined) {
        this.form.has_email = this.default.has_email;
      }
      if (this.form.email_required === undefined) {
        this.form.email_required = this.default.email_required;
      }
      if (this.form.phone_required === undefined) {
        this.form.phone_required = this.default.phone_required;
      }
    },
    copyData() {
      this.$toast.show({
        title: this.$t('generic-str.success'),
        content: 'Script copiado',
        type: 'success',
      });

      this.$clipboard(this.renderScript);
    },
    changePos(pos) {
      this.form.position = pos;
      this.$forceUpdate();
    },
    changeColor(color) {
      this.colors.header.bg = color;
      this.colors.launcher.bg = color;
      this.colors.sentMessage.bg = color;
      this.form.color = color;
    },
    updateWidget() {
      console.log(this.form);
      this.isSending = true;
      axios().put(`/widget/${this.chatId}`, {
        ...this.form,
      }).then(
        () => {
          this.isSending = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('webchat-component.toast.updated'),
            type: 'success',
          });
        },
        (error) => {
          this.content = error;
          console.log(error);
        },
      );
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1;
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } });
      }
    },
    onMessageWasSent(message) {
      // called when the user sends a message
      this.messageList = [...this.messageList, message];
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      console.log('Emit typing event');
    },
    editMessage(message) {
      const mR = this.messageList.find((m) => m.id === message.id);
      mR.isEdited = true;
      mR.data.text = message.data.text;
    },
  },
};
</script>

<style lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
.sc-chat-window{
  z-index: 900 !important;
}
.card-box{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right:0;
}
.default-color{
  background: #0E1421;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
  transition: display 2s;
}
.btn-accordion {
  text-align: left;
}
.btn-accordion i {
  float: right;
  color: #000;
  font-size: 25px;
}
.over-hiden{
  overflow: hidden;
  position: relative;
  min-height: 100px;
}

.box-widget{
  margin: 0px;
  padding: 0px;
}
.box-widget-item:hover{
  box-shadow: 0px 0px 0px 5px rgba(162, 167, 184, 0.51);
}
.box-widget-item{
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.place__2bTkV .boxContainer__3LS_q {
  box-sizing: border-box;
  width: 320px;
  height: 210px;
  position: relative;
  margin: 0 auto;
}
.place__2bTkV .text__1FtTq {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    bottom: 80px;
    text-align: center;
    padding: 0px 28px;
    color: #2D3339;
}
.place__2bTkV .box__chat-pos.top__2W2WB.padding-0__38h9Z, .place__2bTkV .box__chat-pos.bottom__3oEP_.padding-0__38h9Z {
    left: 20px;
}
.place__2bTkV .box__chat-pos.top__2W2WB, .place__2bTkV .box__chat-pos.bottom__3oEP_ {
    width: 93.33333333px;
    height: 20px;
}
.place__2bTkV .box__chat-pos.bottom__3oEP_ {
    bottom: 3px;
}
.pos-inactive{
  background-color: #EAECEF !important;
  cursor: default !important;
}
.place__2bTkV .box__chat-pos {
    box-sizing: border-box;
    background-color: #FFF;
    position: absolute;
    border: 1px #EAECEF solid;
    cursor: pointer;
}
.place__2bTkV .box__chat-pos.top__2W2WB.padding-50__30Irj, .place__2bTkV .box__chat-pos.bottom__3oEP_.padding-50__30Irj {
    left: 112.33333333px;
}
.place__2bTkV .box__chat-pos.top__2W2WB.padding-100__2YkFV, .place__2bTkV .box__chat-pos.bottom__3oEP_.padding-100__2YkFV {
    left: 204.66666667px;
}
.place__2bTkV .box__chat-pos.active_box_chat {
    background-color: #0E1421;
    cursor: default;
}
.place__2bTkV .box__chat-pos.top__2W2WB, .place__2bTkV .box__chat-pos.bottom__3oEP_ {
    width: 93.33333333px;
    height: 20px;
}
.place__2bTkV .box__chat-pos.bottom__3oEP_ {
    bottom: 3px;
}
.place__2bTkV .box__chat-pos {
    box-sizing: border-box;
    background-color: #FFF;
    position: absolute;
    border: 1px #EAECEF solid;
    cursor: pointer;
}
.table-inputs{
  background: #f7f7f7;
  border: solid 1px #f1f1f1;
}
.mt5{
  margin-top: 5px !important;
}
.place__2bTkV .box__chat-pos.left__33EA_.padding-0__38h9Z, .place__2bTkV .box__chat-pos.right__1X0Q3.padding-0__38h9Z {
    top: 20px;
    cursor: default;
    background-color: #EAECEF;
}
.place__2bTkV .box__chat-pos.left__33EA_, .place__2bTkV .box__chat-pos.right__1X0Q3 {
    height: 56.66666667px;
    width: 20px;
}
.place__2bTkV .box__chat-pos.left__33EA_ {
    right: 0px;
}
.place__2bTkV .box__chat-pos {
    box-sizing: border-box;
    background-color: #FFF;
    position: absolute;
    border: 1px #EAECEF solid;
    cursor: pointer;
}
.place__2bTkV .box__chat-pos.left__33EA_.padding-100__2YkFV, .place__2bTkV .box__chat-pos.right__1X0Q3.padding-100__2YkFV {
    top: 131.33333333px;
}
.place__2bTkV .box__chat-pos.left__33EA_ {
    right: 0px;
}
.place__2bTkV .box__chat-pos.left__33EA_.padding-0__38h9Z, .place__2bTkV .box__chat-pos.right__1X0Q3.padding-0__38h9Z {
    top: 20px;
    cursor: default;
    background-color: #EAECEF;
}
.place__2bTkV .box__chat-pos.left__33EA_.padding-50__30Irj, .place__2bTkV .box__chat-pos.right__1X0Q3.padding-50__30Irj {
    top: 75.66666667px;
}
.place__2bTkV .box__chat-pos.left__33EA_.padding-100__2YkFV, .place__2bTkV .box__chat-pos.right__1X0Q3.padding-100__2YkFV {
    top: 131.33333333px;
}
.place__2bTkV .box__chat-pos.top__2W2WB.padding-0__38h9Z, .place__2bTkV .box__chat-pos.bottom__3oEP_.padding-0__38h9Z {
    left: 20px;
}
.place__2bTkV .box__chat-pos.top__2W2WB.padding-50__30Irj, .place__2bTkV .box__chat-pos.bottom__3oEP_.padding-50__30Irj {
    left: 112.33333333px;
}
.place__2bTkV .box__chat-pos.top__2W2WB {
    top: 0px;
    background-color: #EAECEF;
    cursor: default;
}
.place__2bTkV .box__chat-pos {
    box-sizing: border-box;
    background-color: #FFF;
    position: absolute;
    border: 1px #EAECEF solid;
    cursor: pointer;
}
.place__2bTkV .box__chat-pos.top__2W2WB.padding-100__2YkFV, .place__2bTkV .box__chat-pos.bottom__3oEP_.padding-100__2YkFV {
    left: 204.66666667px;
}
.btn-secondary{
  background: none !important;
  border: none;
  color: #000 !important;
}
.btn-secondary:hover{
  background: none !important;
  border: 1px solid #6e85af!important;
  color: #000 !important;
}
.btn-secondary:active{
  background: none !important;
  border: 1px solid #6e85af!important;
  color: #000 !important;
}
.btn-secondary:focus{
  background: none !important;
  border: 1px solid #6e85af!important;
  color: #000 !important;
}
.btn-secondary:hover, .btn-secondary:focus:hover{
  background: none !important;
  border: 1px solid #6e85af!important;
  color: #000 !important;
}
.mt10{
  margin-top: 10px !important;
}
.active_color::after {
  position: absolute;
  display: block;
  content: ' ';
  width: 36px;
  height: 36px;
  top: 0;
  left: 0;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDM2IDM2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTE4IDBjOS45NDEgMCAxOCA4LjA1OSAxOCAxOHMtOC4wNTkgMTgtMTggMThTMCAyNy45NDEgMCAxOCA4LjA1OSAwIDE4IDB6Ii8+CiAgICAgICAgPHBhdGggc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMCAxOC40ODlsNS44NTMgNC45NTJMMjQuNTI3IDEzIi8+CiAgICA8L2c+Cjwvc3ZnPgo=) 0 0 no-repeat;
  background-size: contain;
}
.code-box {
  padding: .5em .7em;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #fff;
  border: 1px solid #949494;
  border-radius: 4px;
}
.render-code{
  color: #989898;
  font-size: 13px;
}
.btn-copy{
  margin-top: 5px;
}
</style>
